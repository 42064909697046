<template>
    <div class="BrandCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
            <el-button type="primary" @click="handleAdd" size="small">添加</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" type="index" width="80" align="center" />
                <el-table-column label="名称" width="160">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.names" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';

export default {
    name: 'BrandCreate',
    data() {
        return {
            tableData: [],
            url: {
                save: '/goods/brand/createExtend',
            },
            loading: false,
        };
    },
    methods: {
        handleSave() {
            const _this = this;
            if (!this.tableData.length) {
                this.$message.error('无数据');
                return false;
            }
            const _params = {
                names: _this.tableData.map((m) => {
                    if (Util.isEmpty(m.names)) {
                        this.$message.error('请填写品牌名称');
                        throw new Error('请填写品牌名称');
                    }
                    return m.names;
                }),
            };
            UrlUtils.PostRemote(this, this.url.save, _params);
        },
        handleAdd() {
            this.tableData = this.tableData.concat({
                names: '',
            });
        },
        handleDelete(index) {
            const _a = this.tableData;
            _a.splice(index, 1);
            this.tableData = _a;
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
